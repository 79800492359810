// .accordion.accordion-faqs {
//     display: flex;
//     flex-wrap: wrap;
//     gap: 16px;
//     padding: 0;
//     margin-top: 40px;
//     background-color: transparent;

//     .accordion-item {
//         background: #161616;
//         box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
//         border-radius: 8px;
//         padding: 15px 20px;
//         width: 100%;

//         @include breakpoint(medium up) {
//             padding: 24px 32px;
//         }

//         &:not(:last-child) {
//             margin: 0;
//         }

//         &.is-active {
//             .accordion-title {
//                 &:before {
//                     content: $icon-minus;
//                     color: $white;
//                     background-color: transparent;
//                     @include breakpoint(large) {
//                         top: 40px;
//                     }
//                 }
//             }
//         }
//     }

//     .accordion-title {
//         border: none;
//         padding: 0;
//         font-weight: bold;
//         padding-#{$opposite-direction}: 60px;
//         color: $white;
//         @include breakpoint(small only) {
//             font-size: 18px;
//             line-height: 1.4;
//         }

//         &:hover,
//         &:focus {
//             background-color: transparent;
//         }

//         &:before {
//             content: $icon-plus;
//             font-family: "icomoon";
//             font-size: 14px;
//             font-weight: normal;
//             width: 32px;
//             height: 32px;
//             line-height: 2.3;
//             border-radius: 50%;
//             border: 1px solid $white;
//             background-color: $white;
//             text-align: center;
//             transition: 300ms all ease-in-out;
//             color: $black;
//             top: 20%;
//             #{$opposite-direction}: 0;
//             @include breakpoint(large) {
//                 width: 42px;
//                 height: 42px;
//                 line-height: 3;
//                 top: 0;
//             }
//         }

//         &:after {
//             background-color: transparent;
//         }
//     }

//     .accordion-content {
//         margin-top: 22px;
//         padding: 0;
//         line-height: 27px;
//         background-color: transparent;
//         border: none;
//         @include breakpoint(large) {
//             width: 90%;
//         }

//         ul,
//         p:not(:last-child) {
//             margin-bottom: 20px;
//         }

//         ul.count {
//             list-style: decimal;
//         }

//         a {
//             text-decoration: underline;
//         }
//     }
// }

.accordion.accordion-faqs { 
    gap: 16px; 
    margin-top: 40px; 

    .accordion-item {
        background: #161616;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        padding: 15px 20px;
        width: 100%;

        @include breakpoint(medium up) {
            padding: 24px 32px;
        }

        &:not(:last-child) {
            margin: 0;
        } 
    }

    .accordion-title { 
        font-weight: 600; 
        @include breakpoint(medium down) {
            font-size: 18px;
            line-height: 25px;
        } 
    }

    .accordion-content {
        margin-top: 22px;
        ul,
        p:not(:last-child) {
            margin-bottom: 20px;
        }

        ul.count {
            list-style: decimal;
        }

        a {
            text-decoration: underline;
        }
    }
}
