.top-tabs {
    margin-top: 40px;
    @include breakpoint(medium up) {
        margin-top: 80px;
    }
    @media screen and (max-height: 810px) {
        margin-top: 40px;
    }
    &__nav {
        display: flex; 
        justify-content: center;
        gap: 20px;
        align-items: center;

        @include breakpoint(small only) {
            gap: 16px;
            justify-content: flex-start;
            flex-wrap: nowrap;
            overflow-x: auto;
            scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }

            -ms-overflow-style: none;
            scrollbar-width: none;
        }

        &-item {
            width: 282px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            @include breakpoint(small only) {
                width: 163px;
                height: 60px;
                flex: 0 0 auto;
            }

            @include breakpoint(medium only) {
                width: 171px;
                height: 60px;
                padding: 14px 25px;
            }

            img {
                mix-blend-mode: luminosity;
                filter: grayscale(1);
                @include breakpoint(small only) {
                    max-width: 100px;
                }
            }
        }

        .is-active {
            border: 0 !important;
            .top-tabs__nav-item {
                background: #272727;
                box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                img {
                    mix-blend-mode: normal;
                    filter: grayscale(0);
                }
            }
            &:focus {
                outline: none;
            }
        }

        .splide__arrows {
            display: none;
        }
    }

    &__items {
        .splide__track {
            @include breakpoint(small only) {
                margin-#{$default-direction}: -24px;
                margin-#{$opposite-direction}: -24px;
            }
        }

        .splide__arrow {
            @include breakpoint(small only) {
                top: 70%;
                transform: translateY(-70%);
            }
        }
    }

    &__item {
        position: relative;
        margin-top: 60px;
        @include breakpoint(medium only) {
            padding: 0;
        }
        @include breakpoint(large) {
            padding: 0 calc(100% / 12);
            margin-top: 80px;
        }
        @media screen and (max-height: 810px) {
           margin-top: 40px;
        }
        &-caption {
            background: $black;
            padding: 20px;
            @include breakpoint(small only) {
                margin: -35px 24px 0;
                position: relative;
                padding: 24px;
            }
            @include breakpoint(medium only) {
                width: 427px;
                padding: 32px;
            }
            @include breakpoint(medium up) {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                #{$opposite-direction}: 0;
            }
            @include breakpoint(large) {
                padding: 51px 64px;
                width: 602px;
                #{$opposite-direction}: calc(100% / 12);
            }
            @media screen and (max-height: 810px) {
               padding: 40px;
            }

            ul {
                margin-#{$default-direction}: 0;
                margin-top: 16px;
                li {
                    list-style: none;
                }
            }
        }

        img {
            @include breakpoint(medium only) {
                width: 80%;
            }
            @media screen and (max-height: 810px) {
               height: 440px;
            }
        }
    }
}

.soon {
    background: rgba($medium-gray, 0.8);
    color: $white;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    width: 100%;
    padding: 9px;
    text-align: center;
}
