html,
body {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    position: relative; 
    &.menu-open {
        overflow: hidden;
        &:before {
            content: "";
            background-color: rgba($black, 0.8);
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
        }
    }
   
}

#main-wrap {
    position: relative;
}

main {
    margin: 0 auto;
    padding: 0;

    @include breakpoint(small only) {
        .grid-container {
            max-width: 480px;
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    @include breakpoint(medium only) {
        .grid-container {
            //padding-#{$opposite-direction}: 0.75rem;
            //padding-#{$default-direction}: 0.75rem;
            //  max-width: 816px;
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}

.section {
    position: relative; 
    &:not(.section-hero):not(.section-hero):not(.section-portfolio):not(.section-spotlight) {
        padding: 40px 0;
        @include breakpoint(large) {
            padding: 80px 0;
        }
        @media screen and (max-height: 810px) {
            padding: 48px 0;
        }
    }

    &__title {
        h2 {
            @include breakpoint(medium down) {
                font-size: 40px;
                line-height: 55px;
            }
            @include breakpoint(large) {
                line-height: 109px;
            }
            @media screen and (max-height: 810px) {
                font-size: 64px;
                line-height: 87px;
            }
        }
    }

    &__desc {
        margin-top: 16px;
        margin-bottom: 40px;

        @include breakpoint(large) {
            width: 894px;
            margin-bottom: 80px;
        }

        @media screen and (max-height: 810px) {
            margin-bottom: 65px;
        }

        p {
            @include paragraph(600);
        }
    }

    &-hero {
        padding: 0;
    }

    &-focus {
        background-color: $black;
    }

    &-portolio,
    &-intouch {
        background-color: $medium-gray;
    }

    &-portfolio,
    &-spotlight {
        padding: 40px 0;
        @include breakpoint(large) {
            padding-top: 80px;
            padding-bottom: 120px;
        }
        @media screen and (max-height: 810px) {
            padding: 48px 0;
        }
    }

    &-intouch {
        h2 {
            margin-bottom: 16px;
        }
        p {
            @include paragraph(600);
            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }

        .email {
            font-weight: 700;
            font-size: 24px;
            line-height: 66px;
            text-decoration-line: underline;
            color: $white;

            @include breakpoint(medium up) {
                font-size: 48px;
            }

            &:hover {
                color: $secondary-color;
            }
        }
    }

    &-team,
    &-spotlight,
    &-faqs {
        &:before {
            //height: calc(830px / 2);
            //width: calc(755px / 2);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: -1;

            @include breakpoint(small only) {
                background-size: 60% !important;
            }

            @include breakpoint(large) {
                // height: 830px;
                // width: 755px;
            }
        }
    }

    &-team {
        &:before {
            content: "";
            background: $black url("../img/bg/bg1.png") top right no-repeat;
            #{$opposite-direction}: 0;
        }
    }

    &-spotlight {
        &:before {
            content: "";
            background: $black url("../img/bg/bg2.png") top left no-repeat;
            #{$default-direction}: 0;
        }
    }

    &-faqs {
        &:before {
            content: "";
            background: $black url("../img/bg/bg3.png") top right no-repeat;
            #{$opposite-direction}: 0;
        }
    }

    &-wedo {
        .section__desc {
            p {
                @include breakpoint(small only) {
                    font-size: 19px;
                }
            }
        }
    }
}

.splide__arrow {
    background: $secondary-color;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 35px;
    padding: 0;
    opacity: 1;
    @include breakpoint(small only) {
        width: 32px;
        height: 32px;
    }
}

.splide__arrow--prev,
.splide__arrows--rtl .splide__arrow--prev {
    #{$default-direction}: 0;
    @include breakpoint(small only) {
        #{$default-direction}: -15px;
    }
    @include breakpoint(medium only) {
        #{$default-direction}: -20px;
    }
}
.splide__arrow--next,
.splide__arrows--rtl .splide__arrow--next {
    #{$opposite-direction}: 0;
    @include breakpoint(small only) {
        #{$opposite-direction}: -15px;
    }
    @include breakpoint(medium only) {
        #{$opposite-direction}: -25px;
    }
}

.icon-arrow-left:before,
.icon-arrow-right:before {
    color: $black;
}