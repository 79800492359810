.card-tiles {
    @include breakpoint(small only) {
        gap: 24px;
    }
}
.card-tile {
    text-align: center;

    &__img {
        background: #1b1b1b;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: -20px;
            z-index: 1;  
            background-size: contain;
            background-repeat: no-repeat;
        }

        &--investing {
            &:before {
                background-image: url("../img/whatwedo/shape-investing.png");
                right: 20px; 
                width: 217px;
                height: 217px; 
                @include breakpoint(medium only) {
                    width: 150px;
                    height: 100%;
                }
                @include breakpoint(large) {
                    width: 260px;
                    height: 260px;
                }
                @media screen and (max-width: 1024px) and (min-width: 800px) {
                    width: 150px;
                    height: 100%;
                }
                
                
            }
        }

        &--financial {
            &:before {
                background-image: url("../img/whatwedo/shape-financial.png");
                right: -30px; 
                width: 142px;
                height: 142px;
                @include breakpoint(large) {
                    width: 252px;
                    height: 215px;
                }
                @media screen and (max-width: 1024px) {
                    width: 150px;
                    height: 150px;
                }
            }
        } 

        &--potential {
            &:before {
                background-image: url("../img/whatwedo/shape-potential.png");
                left: 50px; 
                width: 117px;
                height: 202px;
                @include breakpoint(large) {
                    width: 170px;
                    height: 170px;
                }
                @media screen and (max-width: 1024px)  and (min-width: 800px) {
                    width: 130px;
                    height: 130px;
                }
            }
        }
    }
    &__title {
        margin-top: 16px;
        @include breakpoint(large) {
            margin-top: 32px;
        }
        @media screen and (max-height: 810px) {
            margin-top: 16px;
        }
        h4 { 
            line-height: 27px;
            @include breakpoint(medium up) {
                line-height: 33px;
            }
        }
    }

    &__desc {
        margin-top: 16px;
        p {
            font-size: 14px;
            line-height: 19px;
            @include breakpoint(medium up) {
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}
