.spotlight-gallery {
    margin-top: 40px;
    @include breakpoint(large) {
        margin-top: 80px;
    }
    @media screen and (max-height: 810px) {
        margin-top: 40px;
    }
    &__item {
        max-width: 894px;
        margin: auto;
        width: 100%;
        position: relative;
        @include breakpoint(small only) {
            height: 500px;
        }
        @include breakpoint(medium only) {
            height: 100%;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
        }

        img {
            @include breakpoint(small only) {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__details {
        padding: 24px;
        position: absolute;
        bottom: 0;

        h4 {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            //text-decoration-line: underline;
            margin-bottom: 8px;
            a {
                text-decoration: underline;
            }
        }
        span {
            font-size: 16px;
            line-height: 22px;
            text-transform: capitalize;
        }
    }
    &__thumbnails {
        margin-top: 33px;
        display: flex;
        gap: 24px;
        justify-content: center;
        align-items: center;
        @include breakpoint(small only) {
            flex-wrap: wrap;
        }

        @media screen and (max-height: 810px) {
            margin-top: 24px;
        }

        .is-active {
            border: 0 !important;
            img {
                mix-blend-mode: normal;
                filter: grayscale(0);
                transition: 300ms all ease;
            }
        }

        .splide__arrows {
            display: none;
        }
    }
    &__thumbnail {
        position: relative; 
        @include breakpoint(large) {
            width: 80px;
            height: 80px;
        }
        img {
            cursor: pointer;
            // position: absolute;
            // top: 0;
            // left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            mix-blend-mode: luminosity;
            transition: 300ms all ease;
            filter: grayscale(1);

            @include breakpoint(small only) {
                width: 56px;
                height: 56px;
            }

            &:hover {
                mix-blend-mode: normal;
                transition: 300ms all ease;
            }
        }
    } 
}
