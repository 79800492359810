$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;


$icon-minus: "\e90c";
$icon-plus: "\e90b";
$icon-arrow-left: "\e904";
$icon-arrow-right: "\e905";
$icon-linkedin: "\e906";
$icon-twitter: "\e909";
$icon-menu: "\e90a";
$icon-arrow_right: "\e902";
$icon-arrow_left: "\e903";


