.hero {
    padding-#{$default-direction}: 24px;
    position: relative;

    @include breakpoint(small only) {
        padding-top: 32px;
    } 
   
    @include breakpoint(large) {
        padding-#{$default-direction}: calc((100vw - 1200px) / 2);
    }
 
    @media screen and (max-width: 1024px) {
        padding-#{$default-direction}: 24px;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--bottom {
            align-items: flex-end;
        }

        @include breakpoint(small only) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__caption {
        max-width: 444px;
        position: relative;
        z-index: 2;
        @include breakpoint(medium only) {
            width: 500px;
        }
        h1 {
            padding: 0; 
            @include breakpoint(medium down) {
                font-size: 40px;
                strong{
                    font-size: 48px;
                }
            } 
            // @include breakpoint(1024) {
            //     font-size: 60px;
            // }
            @include breakpoint(large) {
                line-height: 1.3;
                font-size: 64px;
                strong{
                    font-size: 80px;
                }
            }
        }

        .hero__item--bottom & {
            @include breakpoint(large) {
                margin-bottom: 120px;
            }
        }
    }
    &__img {
        flex: auto;
        display: flex;
        justify-content: flex-end;
        img{
            @include breakpoint(large) {
                height: calc( 100vh - 92px);
            }
        }
    }

    &__video {
        height: calc(100vh - 92px);
        @include breakpoint(large) {
            height: 810px;
        }
        &:before {
            background: #111619;
            opacity: 0.6;
            content: "";
            position: absolute;
            z-index: 1;
            width: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }
        &:after {
            content: "";
            display: block;
            height: 0;
            width: 100%;
            padding-bottom: 56.25%;
        }

        video {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            object-fit: cover;
        }
    }

    &.splide {
        .splide__pagination {
            gap: 4px;
            padding: 0;
            bottom: 10px;
            @include breakpoint(large) {
                gap: 8px;
                position: absolute;
                width: fit-content;
                left: calc((100vw - 1200px) / 2);
                bottom: 210px;
            }
        }

        .splide__pagination__page {
            width: 20px;
            height: 4px;
            border-radius: 0;
            background-color: #434343;
            transform: initial;

            @include breakpoint(medium up) {
                width: 40px;
                height: 8px;
            }

            &.is-active {
                background-color: $white;
            }
        }
    }
}
