.accordion {
  display: flex;
  flex-wrap: wrap; 
  padding: 0; 
  background-color: transparent;

  .accordion-item {  
      width: 100%; 

      &.is-active {
          .accordion-title {
              &:before {
                  content: $icon-minus;
                  color: $secondary-color;
                  background-color: transparent;
                  @include breakpoint(large) {
                      top: 0;
                  }
              }
          }
      }
  }

  .accordion-title {
      border: none;
      padding: 0;
      font-weight: bold;
      padding-#{$opposite-direction}: 60px;
      color: $white;
      
      border-bottom: 0 !important;
      
      &:hover,
      &:focus {
          background-color: transparent;
      }

      &:before {
          content: $icon-plus;
          font-family: "icomoon";
          font-size: 14px;
          font-weight: normal;
          width: 32px;
          height: 32px;
          line-height: 2.3;
          border-radius: 50%;
          border: 1px solid $secondary-color;
          background-color: $secondary-color;
          text-align: center;
          transition: 300ms all ease-in-out;
          color: $black;
          top: 20%;
          #{$opposite-direction}: 0;
          @include breakpoint(large) {
              width: 42px;
              height: 42px;
              line-height: 3;
              top: 0;
          }
      }

      &:after {
          background-color: transparent;
      }
  }

  .accordion-content { 
      padding: 0;
      line-height: 27px;
      background-color: transparent;
      border: none;
      border-bottom: 0 !important;
      @include breakpoint(large) {
          width: 90%;
      } 

      ul.count {
          list-style: decimal;
      }

      a {
          text-decoration: underline;
      }
  }
   
}
