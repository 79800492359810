$font-path: "../fonts";

@import "icomoon";
 
@font-face {
    font-family: "Avenir Next";
    src: url("#{$font-path}/AvenirNextLTPro-Regular.woff2") format("woff2"),
        url("#{$font-path}/AvenirNextLTPro-Regular.woff") format("woff"),
        url("#{$font-path}/AvenirNextLTPro-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Avenir Next";
    src: url("#{$font-path}/AvenirNextLTPro-Bold.woff2") format("woff2"),
        url("#{$font-path}/AvenirNextLTPro-Bold.woff") format("woff"),
        url("#{$font-path}/AvenirNextLTPro-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
} 

@font-face {
    font-family: "Avenir Next";
    src: url("#{$font-path}/AvenirNextLTPro-Demi.woff2") format("woff2"),
        url("#{$font-path}/AvenirNextLTPro-Demi.woff") format("woff"),
        url("#{$font-path}/AvenirNextLTPro-Demi.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'SRMGFavoritArabic Favorit Arabic';
    src: url('#{$font-path}/SRMGFavoritArabicFavoritArabic-Regular.woff2') format('woff2'),
        url('#{$font-path}/SRMGFavoritArabicFavoritArabic-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SRMGFavoritArabic Favorit Arabic';
    src: url('#{$font-path}/SRMGFavoritArabicFavoritArabic-Book.woff2') format('woff2'),
        url('#{$font-path}/SRMGFavoritArabicFavoritArabic-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SRMGFavoritArabic Favorit Arabic';
    src: url('#{$font-path}/SRMGFavoritArabicFavoritArabic-Light.woff2') format('woff2'),
        url('#{$font-path}/SRMGFavoritArabicFavoritArabic-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SRMGFavoritArabic Favorit Arabic';
    src: url('#{$font-path}/SRMGFavoritArabicFavoritArabic-Bold.woff2') format('woff2'),
        url('#{$font-path}/SRMGFavoritArabicFavoritArabic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SRMGFavoritArabic Favorit Arabic';
    src: url('#{$font-path}/SRMGFavoritArabicFavoritArabic-Medium.woff2') format('woff2'),
        url('#{$font-path}/SRMGFavoritArabicFavoritArabic-Medium.woff') format('woff'); 
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SRMGFavoritArabic Favorit Arabic Variable';
    src: url('#{$font-path}/SRMGFavoritArabicFavoritArabicVariable-Regular.woff2') format('woff2'),
        url('#{$font-path}/SRMGFavoritArabicFavoritArabicVariable-Regular.woff') format('woff'),
        url('#{$font-path}/SRMGFavoritArabicFavoritArabicVariable-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

