.card-team {
    position: relative;
    @include breakpoint(large) {
        height: 374px;
    }
    &:hover {
        .card-team__details {
            opacity: 1;
            transition: 300ms all ease; 
            background: rgba(137, 46, 255, 0.4);
            backdrop-filter: blur(7.5px);
        }
    }
    &__img {
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            mix-blend-mode: luminosity;
            object-fit: cover;
            @include breakpoint(large) {
                object-fit: none;
            }
        }
    }
    &__details {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: 300ms all ease;
        top: 0;
        left: 0;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @include breakpoint(large) {
            padding: 24px;
        }

        h3 {
            font-weight: 800;
            font-size: 20px;
            line-height: 27px;
            text-transform: uppercase;
            margin-bottom: 0;
            @include breakpoint(large) {
                font-size: 28px;
                line-height: 34px;
            }
        }

        h5 {
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 0;
            @include breakpoint(medium down) {
                display: none;
            }
        }

        p {
            margin-top: 4px;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            @include breakpoint(large) {
                margin-top: 17px;
                font-size: 20px;
                line-height: 27px;
            }
        }
    }

    &__social {
        margin-top: 9px;
        @include breakpoint(large) {
            margin-top: 29px;
        }
        ul {
            display: flex;
            gap: 25px;
            margin-left: 0;
            font-size: 16px;
            @include breakpoint(large) {
                font-size: 24px;
            }
            li {
                list-style: none;
            }
        }
    }
}
