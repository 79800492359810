.teams {
    display: flex;
    @include breakpoint(small only){
        gap: 0; 
        flex-wrap: wrap;
        justify-content: center;
    }
    @include breakpoint(medium up) { 
        flex-wrap: wrap;
    }
    > div {
        @include breakpoint(small only) {
            width: 50%;
        } 
        @include breakpoint(medium up) {
            width: 25%;
        }
    }
}
