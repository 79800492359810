@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


.icomoon-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;     
    color: #fff;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;     
    color: #fff;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;     
    color: #fff;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;     
    color: #fff;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-arrow_right {
  &:before {
    content: $icon-arrow_right; 
  }
}
.icon-arrow_left {
  &:before {
    content: $icon-arrow_left; 
  }
}


