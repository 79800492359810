.side-tabs {
    cursor: pointer;

    @include breakpoint(large) {
        display: flex;
        gap: 24px;
    }

    &__nav {
        @include breakpoint(large) {
            flex: 1 0 auto;
            max-width: 486px;
            flex-direction: column;
        }
        h3 {
            margin: 0;
        }

        &-item {
            @include breakpoint(medium down) {
                padding-bottom: 23px;
                margin-top: 23px;
            }

            @include breakpoint(large) {
                padding: 20px;
            }

            &:not(:last-child) {
                border-bottom: 2px solid rgba($white, 0.2);
            }

            .accordion-title {
                font-weight: 700;
                line-height: 33px;
                position: relative;
                @include breakpoint(medium down) {
                    font-size: 20px;
                    line-height: 27px;
                }
            }

            .accordion-content {
                margin-top: 16px;
            }

            p {
                font-weight: 500; 
                font-size: 16px;
                line-height: 22px;
                @include breakpoint(large) {
                    font-size: 20px;
                    line-height: 27px;
                }
            }
        }

        &-img {
            margin-top: 50px;
            text-align: center;
        }
    }

    &__img {
        cursor: default;
        @include breakpoint(medium down) {
            display: none;
        }
        @include breakpoint(large) {
            flex: 1;
        }

        .splide__slide {
            text-align: #{$opposite-direction};
        }
    }
}
