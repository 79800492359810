.main-header-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    background: $medium-gray;
    padding: 25px 0;

    @include breakpoint(small only) {
        padding: 20px 0;
    }

    .sticky & {
        padding: 15px 0;
    }

    &__left {
        flex: 0 0 auto;
    }
    &__right {
        flex: auto;
        display: flex;
        justify-content: flex-end;
    }
}

.logo {
    .logo__img {
        .sticky & {
            width: 128px;
        }
    }
}

.main-header {
    background-color: $medium-gray;
    &.sticky {
        z-index: 9;
    }
}

.main-nav {
    &__items {
        display: flex;
        gap: 59px;
        margin: 0;
        padding: 0;
        [dir="rtl"] & {
            gap: 40px;
        }
        @include breakpoint(medium down) {
            display: none;
        }
    }
    &__item {
        display: block;
        a {
            color: $white;
            font-size: 15px;
            line-height: 32px;
            &:hover,
            &.active {
                color: $secondary-color;
            }
        }
    }

    &__toggles {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    &__toggle {
        color: $white;
    }
}

.navpanel {
    position: fixed;
    top: 0px;
    height: 100vh;
    z-index: 9;
    #{$opposite-direction}: 0;

    width: 360px;
    padding: 30px;
    background-color: $black;
    display: none;

    li {
        list-style: none;
    }

    a {
        color: $white;
        &:hover {
            color: $primary-color;
        }
    }

    .close-panel {
        font-size: 30px;
    }

    &-content {
        margin-top: 30px;

        .navpanel-menu {
            margin-top: 30px;

            ul {
                margin-#{$default-direction}: 0;
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
        }
    }
}
